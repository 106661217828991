import React from "react";
import axios, { CancelToken } from "axios";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import {
  ThemeProvider,
  NotificationProvider,
  ModalProvider,
} from "@ubisend/pulse-components";
import { AuthProvider } from "./Providers/index";

import App from "./App";
import "./styles/global.css";

const defaultQueryFn = async ({ queryKey }) => {
  const [key, params = {}] = queryKey;

  const source = CancelToken.source();

  const promise = axios.get(key, {
    params,
    cancelToken: source.token,
  });

  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  const { data } = await promise;

  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: false,
    },
  },
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const defaultColours = {
  // Core
  white: "#ffffff",
  black: "#1a1a1a",
  grey: "#908c97",

  // Brand
  primary: "#684b99",
  secondary: "#b15199",

  // Status
  positive: "#3bb6a1",
  danger: "#e62851",
  warning: "#ffc25d",
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={defaultColours}>
        <ModalProvider>
          <NotificationProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </QueryClientProvider>
          </NotificationProvider>
        </ModalProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
