import React from "react";
import dayjs from "dayjs";
import { useQueryClient, useMutation } from "react-query";

import {
  InnerPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  StretchPanel,
  Button,
  useModal,
  NoResults,
  Flex,
  Tag,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";

import { revokeInvite } from "../../api/index";

const ExistingInvites = () => {
  const query = useQuery("users/invites");
  const { showModal, hideModal } = useModal();
  const queryClient = useQueryClient();

  const mutation = useMutation(revokeInvite, {
    onSuccess: () => queryClient.refetchQueries("users/invites"),
  });

  const handleRemove = (invite) => {
    showModal({
      header: "Revoke Invitation",
      body: `Are you sure you want to revoke the invitation for ${invite.email}?`,
      handleConfirm: async () => {
        try {
          mutation.mutate({ id: invite.id });
        } finally {
          hideModal();
        }
      },
    });
  };

  return (
    <InnerPanel header="Invitations" mt>
      <StretchPanel>
        <Table loading={query.isLoading} loadingColumns={4}>
          {!query.isLoading && query.data?.data?.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Email</TableHeadCell>
                  <TableHeadCell>Role</TableHeadCell>
                  <TableHeadCell>Date</TableHeadCell>
                  <TableHeadCell>Actions</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {query.data.data.map((invite, key) => (
                  <TableRow key={key}>
                    <TableCell>{invite.email}</TableCell>
                    <TableCell>
                      <Tag>{invite.role}</Tag>
                    </TableCell>
                    <TableCell>{`${dayjs(invite.sent_at).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}`}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleRemove(invite)}>
                        - Revoke
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>

        {!query.isLoading && query.data?.data?.length === 0 && (
          <Flex middle>
            <NoResults
              text="You haven't invited anyone yet"
              subtitle="Go get some mates and come back later"
            />
          </Flex>
        )}
      </StretchPanel>
    </InnerPanel>
  );
};

export default ExistingInvites;
