import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import {
  PageWrapper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  Flex,
  PanelSlider,
  StretchPanel,
  Button,
  NoResults,
  NotificationContext,
  usePaginationReducer,
  useFilterReducer,
  useModal,
  Pagination,
  FilterMenu,
  Label,
  TextInput,
  Tag,
} from "@ubisend/pulse-components";
import Icon from "@ubisend/pulse-icons";
import { useQuery } from "@ubisend/pulse-hooks";

import { TagSelect, Deploy } from "../Components/index";

import {
  ProjectInfo,
  DeployStatus,
  PermissionFilter,
  ProjectSettings,
} from "../Components/index";
import {
  deployProject as deployProjectApi,
  updateProjectSettings,
} from "../api/projects";

dayjs.extend(relativeTime);

const Projects = () => {
  const [showProjectInfo, setShowProjectInfo] = useState();
  const [editSettings, setEditSettings] = useState(false);
  const [showDeployments, setShowDeployments] = useState(false);

  const { showSuccess } = useContext(NotificationContext);
  const { showModal, hideModal } = useModal();
  const queryClient = useQueryClient();
  const pagination = usePaginationReducer({ id: "projects" });

  const filters = useFilterReducer({
    id: "projects",
    initialFilters: { name: "", groups: [2] },
    options: { pagination },
  });

  const query = useQuery([
    "projects",
    { ...pagination.params, ...filters.form },
  ]);

  const { mutate: deployProject } = useMutation(deployProjectApi);

  const handleDeploy = (project) => {
    showModal({
      header: "Deploy Project",
      body: `Are you sure you want to deploy ${project.name}?`,
      handleConfirm: async () => {
        try {
          const response = await deployProject({ id: project.project_id });

          if (response?.status === 200) {
            showSuccess(`Successfully deployed project`);
            queryClient.invalidateQueries("projects");
          }
        } finally {
          hideModal();
        }
      },
    });
  };

  const { mutate: updateSettings } = useMutation(updateProjectSettings, {
    onSuccess: () => {
      showSuccess(`Successfully updated settings`);
      setEditSettings(false);
      queryClient.invalidateQueries("projects");
    },
  });

  const handleProjectSettingSubmit = (settings) => updateSettings({ settings });

  const handleGroupsChange = (groups) => {
    filters.updateFilters({
      groups: groups ? groups.map((group) => group.value) : [],
    });
  };

  const handleNameChange = (event) => {
    const name = event.target.value;

    filters.updateFilters({ name });
  };

  return (
    <PageWrapper
      header={"All Pulse Projects"}
      subheader={
        query.dataUpdatedAt
          ? `Last updated: ${dayjs(query.dataUpdatedAt).from(dayjs())}`
          : ""
      }
      actions={
        <Flex xSpace>
          <FilterMenu
            buttonProps={{ loading: query.isLoading }}
            {...filters.props}
          >
            <Flex style={{ width: "20rem" }} col>
              <Flex col fat mb>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  placeholder="Installation name"
                  value={filters.filters.name}
                  onChange={handleNameChange}
                />
              </Flex>
              <Flex col fat>
                <Label htmlFor="tags">Tags</Label>
                <TagSelect
                  id="tags"
                  placeholder="Filter installs by tag"
                  for="installs"
                  value={filters.filters.groups}
                  onChange={handleGroupsChange}
                />
              </Flex>
            </Flex>
          </FilterMenu>

          <PermissionFilter permission="edit deployments">
            <Button as={Link} to={"/projects/create"}>
              <Flex xSpaceSm>
                <Icon outline type="plus" />
                <span>Create Project</span>
              </Flex>
            </Button>
          </PermissionFilter>
          <PermissionFilter permission="edit deployments">
            <Button
              variant="secondary"
              onClick={() => setShowDeployments(true)}
            >
              <Flex xSpaceSm center>
                <Icon outline type="airplane" />
                <span>Deploy</span>
              </Flex>
            </Button>
          </PermissionFilter>
        </Flex>
      }
    >
      {editSettings && (
        <ProjectSettings
          project={editSettings}
          handleCancel={() => setEditSettings(false)}
          submit={handleProjectSettingSubmit}
        />
      )}
      {showDeployments && (
        <Deploy
          projects={query.isSuccess && query.data.data}
          handleCancel={() => setShowDeployments(false)}
        />
      )}
      {query.isSuccess && query.data.data.length === 0 && (
        <NoResults
          text="No projects found"
          subtitle="Why not create a shiny new one"
        />
      )}
      {query.isSuccess && query.data.data.length > 0 && (
        <StretchPanel>
          <Table loading={query.isLoading} loadingColumns={4}>
            <TableHead>
              <TableRow>
                <TableHeadCell>Client Name</TableHeadCell>
                <TableHeadCell>App Url</TableHeadCell>
                <TableHeadCell>Tags</TableHeadCell>
                <TableHeadCell>Current State</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {!query.isLoading && query.data?.data.length > 0 && (
              <TableBody>
                {query.data.data.map((project, key) => (
                  <TableRow key={key}>
                    <TableCell>{project.name}</TableCell>
                    <TableCell>
                      <a
                        target="blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#684b99" }}
                        href={`https://${project.app_url}`}
                      >
                        {project.app_url}
                      </a>
                    </TableCell>
                    <TableCell>
                      {project.tags.length > 0 && (
                        <Flex fat xSpaceSm>
                          {project.tags.map((tag) => (
                            <Tag>{tag.name}</Tag>
                          ))}
                        </Flex>
                      )}
                    </TableCell>
                    <TableCell>
                      <DeployStatus value={project.state.name} />
                    </TableCell>
                    <TableCell>
                      <Flex right xSpace>
                        <PermissionFilter permission="edit deployments">
                          <Button
                            variant="secondary"
                            aria-label={`deploy-button-${key}`}
                            disabled={project.state.identifier === "deploying"}
                            onClick={() => handleDeploy(project)}
                          >
                            <Flex xSpaceSm center>
                              <Icon outline type="airplane" />
                              <span>Deploy</span>
                            </Flex>
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter permission="edit deployments">
                          <Button
                            as={Link}
                            disabled={
                              !project.client_secret || !project.client_id
                            }
                            to={{
                              pathname:
                                !project.client_secret || !project.client_id
                                  ? "/"
                                  : `/licenses/${project.project_id}`,
                              state: { name: project.name },
                            }}
                          >
                            <Flex xSpaceSm>
                              <Icon outline type="key" />
                              <span>Licenses</span>
                            </Flex>
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter permission="edit deployments">
                          <Button
                            variant="secondary"
                            aria-label={`settings-button-${key}`}
                            disabled={project.state.identifier !== "deployed"}
                            onClick={() => setEditSettings(project)}
                          >
                            <Flex xSpaceSm center>
                              <Icon outline type="cog" />
                              <span>Settings</span>
                            </Flex>
                          </Button>
                        </PermissionFilter>
                        <Button
                          aria-label={`info-button-${key}`}
                          colour="primary"
                          onClick={() => setShowProjectInfo(project)}
                        >
                          <Flex xSpaceSm>
                            <Icon outline type="eye" />
                            <span>Info</span>
                          </Flex>
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {query.showPagination && (
            <Pagination pagination={query.data.meta} {...pagination.props} />
          )}
        </StretchPanel>
      )}

      {showProjectInfo && (
        <PanelSlider
          header={showProjectInfo.name}
          handleHide={() => setShowProjectInfo(null)}
        >
          <Flex mt col>
            <ProjectInfo project={showProjectInfo} />
          </Flex>
        </PanelSlider>
      )}
    </PageWrapper>
  );
};
export default Projects;
