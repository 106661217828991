import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  Label,
  TextInput,
  FormGroup,
} from "@ubisend/pulse-components";

import { TagSelect } from "../Components/index";

const ProjectSettings = ({ project: initialProject, submit, handleCancel }) => {
  const [project, setProject] = useState({
    id: initialProject.project_id,
    tags: initialProject.tags ? initialProject.tags.map((tag) => tag.id) : [],
    client_id: initialProject.client_id,
    secret_is_set: initialProject.client_secret === true,
    client_secret: "",
  });

  const handleTagsChange = (tags) => {
    setProject({ ...project, tags: tags.map(({ value }) => value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    submit({
      client_secret:
        project.client_secret === "" ? null : project.client_secret,
      ...project,
    });
  };

  return (
    <ModalPortal>
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Edit Project Settings</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="tags">Tags</Label>
                <TagSelect
                  id="tags"
                  value={project.tags}
                  onChange={handleTagsChange}
                  for="installs"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="id">Client ID</Label>
                <TextInput
                  id="id"
                  name="client_id"
                  placeholder="420"
                  value={project.client_id}
                  onChange={(event) =>
                    setProject({ ...project, client_id: event.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="name">Client Secret</Label>
                {project.secret_is_set === true && (
                  <Flex middle xSpace>
                    <span>Secret is already set</span>
                    <Button
                      onClick={() =>
                        setProject({ ...project, secret_is_set: false })
                      }
                    >
                      Change
                    </Button>
                  </Flex>
                )}

                {project.secret_is_set !== true && (
                  <TextInput
                    id="name"
                    name="client_secret"
                    placeholder="S3cr3t_K3Y"
                    value={project.client_secret}
                    onChange={(event) =>
                      setProject({
                        ...project,
                        client_secret: event.target.value,
                      })
                    }
                  />
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

ProjectSettings.propTypes = {
  project: PropTypes.any,
  submit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ProjectSettings;
