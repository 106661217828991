import React, { useState, useContext } from 'react';

import {
  PageWrapper,
  Button,
  Flex,
  Panel,
  TextInput as Input,
  Label,
  InnerPanel,
  Toggle,
  Explainer,
  NotificationContext
} from '@ubisend/pulse-components';

import kebabifier from '../utils/kebabifier';
import { storeProject } from '../api/projects';

const defaultProject = {
  name: '',
  app_url: '',
  app_repo: '',
  api_repo: '',
  api_url: '',
  bot_server_repo: '',
  bot_server_url: ''
};

const CreateProject = () => {
  const [advancedMode, setAdvancedMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(defaultProject);

  const { showSuccess } = useContext(NotificationContext);

  const toggleAdvancedMode = () => setAdvancedMode(!advancedMode);

  const bootstrapValues = () => {
    const kebabedAppName = kebabifier(project.name);

    setProject({
      ...project,
      app_url: `${kebabedAppName}.ubisend.io`,
      app_repo: `ubisend/${kebabedAppName}-app`,
      api_repo: `ubisend/${kebabedAppName}-api`,
      api_url: `${kebabedAppName}-api.ubisend.io`,
      bot_server_repo: `ubisend/${kebabedAppName}-bot-server`,
      bot_server_url: `${kebabedAppName}-bot-server.ubisendaws.com`
    });
  };

  const handleAppNameChange = e =>
    setProject({ ...project, name: e.target.value });

  const handleAppUrlChange = e =>
    setProject({ ...project, app_url: e.target.value });

  const handleAppRepoChange = e =>
    setProject({ ...project, app_repo: e.target.value });

  const handleApiRepoChange = e =>
    setProject({ ...project, api_repo: e.target.value });

  const handleApiUrlChange = e =>
    setProject({ ...project, api_url: e.target.value });

  const handleBotServerRepoChange = e =>
    setProject({ ...project, bot_server_repo: e.target.value });

  const handleBotServerUrlChange = e =>
    setProject({ ...project, bot_server_url: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const response = await storeProject(project);

    if (response?.data?.data?.name) {
      showSuccess(`Successfully created project "${project.name}"`);
      setProject(defaultProject);
      setLoading(false);
    }
  };

  const valid = Boolean(project.name.length > 0);

  return (
    <PageWrapper
      header="Create a new project"
      actions={
        <Flex center>
          <Flex mr>
            <Label mb={false} className="mb-0">
              Advanced mode:
            </Label>
          </Flex>
          <Toggle
            title="advanced-mode"
            on={advancedMode}
            handleToggleChange={toggleAdvancedMode}
          />
        </Flex>
      }>
      <Panel>
        <form onSubmit={handleSubmit}>
          {!advancedMode && (
            <>
              <Explainer>
                A new project will be set up using the app name given below.
                This could be the company name or company project name and will
                be used to create the repos, urls and backend resources.
                Selecting advanced mode from the toggle at the top will enable
                editing of these options, or leave it off and let us do the
                complicated stuff for you.
              </Explainer>
              <Flex col mb mt>
                <Label htmlFor="name-simple">App Name</Label>
                <Input
                  id="name-simple"
                  placeholder="company-name"
                  value={project.name}
                  onChange={handleAppNameChange}
                />
              </Flex>
            </>
          )}
          {advancedMode && (
            <>
              <h3 style={{ marginTop: 0 }}>App info</h3>
              <InnerPanel>
                <Flex col mb>
                  <Label htmlFor="name-advanced">App Name</Label>
                  <Flex xSpace>
                    <Input
                      id="name-advanced"
                      placeholder="company-name"
                      value={project.name}
                      onChange={handleAppNameChange}
                    />
                    <Button
                      disabled={project.name.length === 0}
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={bootstrapValues}>
                      Autofill values
                    </Button>
                  </Flex>
                </Flex>

                <Flex col mb>
                  <Label htmlFor="app-url">App URL</Label>
                  <Input
                    id="app-url"
                    placeholder="company-name.ubisend.io"
                    value={project.app_url}
                    onChange={handleAppUrlChange}
                  />
                </Flex>

                <Flex col mb>
                  <Label htmlFor="app-repo">App Repo</Label>
                  <Input
                    id="app-repo"
                    placeholder="ubisend/company-name-app"
                    value={project.app_repo}
                    onChange={handleAppRepoChange}
                  />
                </Flex>
              </InnerPanel>
            </>
          )}

          {advancedMode && (
            <>
              <h3>API info</h3>
              <InnerPanel>
                <Flex col mb>
                  <Label htmlFor="api-repo">API Repo</Label>
                  <Input
                    id="api-repo"
                    placeholder="ubisend/company-name-api"
                    value={project.api_repo}
                    onChange={handleApiRepoChange}
                  />
                </Flex>

                <Flex col mb>
                  <Label htmlFor="api-url">API URL</Label>
                  <Input
                    id="api-url"
                    placeholder="company-name.ubisend.io"
                    value={project.api_url}
                    onChange={handleApiUrlChange}
                  />
                </Flex>
              </InnerPanel>

              <h3>Bot Server info</h3>
              <InnerPanel>
                <Flex col mb>
                  <Label htmlFor="bot-repo">Bot Server Repo</Label>
                  <Input
                    id="bot-repo"
                    placeholder="ubisend/company-name-bot-server"
                    value={project.bot_server_repo}
                    onChange={handleBotServerRepoChange}
                  />
                </Flex>
                <Flex col mb>
                  <Label htmlFor="bot-url">Bot Server URL</Label>
                  <Input
                    id="bot-url"
                    placeholder="company-name-bot-server.ubisendaws.com"
                    value={project.bot_server_url}
                    onChange={handleBotServerUrlChange}
                  />
                </Flex>
              </InnerPanel>
            </>
          )}
          <Flex mt>
            <Button type="submit" disabled={!valid} loading={loading}>
              Create
            </Button>
          </Flex>
        </form>
      </Panel>
    </PageWrapper>
  );
};
export default CreateProject;
