import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { motion } from "@ubisend/framer-motion";
import {
  Sidebar as PulseSideBar,
  SidebarItem as PulseSideBarItem,
  useTheme,
  Flex,
  Span,
} from "@ubisend/pulse-components";

import Icon from "@ubisend/pulse-icons";

import SidebarItem from "./SidebarItem";
import Logo from "../../images/logo.svg";
import { useAuth } from "../../hooks/index";

const Image = styled.img`
  width: 75%;
  height: 100%;
`;

const containerAnim = {
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const links = [
  {
    to: "/dashboard",
    label: "Dashboard",
    icon: "home",
  },
  {
    to: "/reports",
    label: "reports",
    icon: "chartBar",
    permission: "view reports",
  },
  {
    to: "/users",
    label: "users",
    icon: "userGroup",
    permission: "view users",
  },
];

const Sidebar = () => {
  const theme = useTheme();
  const { user } = useAuth();

  return (
    <>
      <PulseSideBar aria-label="main-sidebar" type="client">
        <Flex middle fat>
          <Image src={Logo} />
        </Flex>
        <Flex grow fat xHidden col yScroll>
          <Flex padSm col between grow ySpace>
            <motion.div variants={containerAnim} initial="hide" animate="show">
              {links
                .filter((link) => {
                  if (!link.permission) {
                    return link;
                  }

                  return user.permissions.includes(link.permission);
                })
                .map((link, key) => (
                  <SidebarItem
                    handleOpen={() => {}}
                    open={false}
                    key={`${key}-${link.label}`}
                    type="client"
                    {...link}
                  />
                ))}
            </motion.div>
            <Flex col fat>
              <PulseSideBarItem type="client">
                <Link to="/logout">
                  <Flex center xSpaceSm>
                    <Icon
                      type="logout"
                      colour={theme.secondary}
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <Span>Logout</Span>
                  </Flex>
                </Link>
              </PulseSideBarItem>
            </Flex>
          </Flex>
        </Flex>
      </PulseSideBar>
    </>
  );
};

export default Sidebar;
export { links };
