import React from "react";
import PropTypes from "prop-types";

import { Flex, Indicator } from "@ubisend/pulse-components";

const DeployStatus = ({ value }) => (
  <Flex left center xSpaceSm>
    <Indicator colour={value === "Failed" ? "danger" : "positive"} />
    <div>{value}</div>
  </Flex>
);

DeployStatus.propTypes = {
  value: PropTypes.any,
};

export default DeployStatus;
