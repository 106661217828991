import PropTypes from "prop-types";

import { useAuth } from "../hooks/index";

const PermissionFilter = ({ children, permission }) => {
  const { user } = useAuth();

  if (!user || !user.permissions) {
    return null;
  }

  if (user.permissions.includes(permission)) {
    return children;
  }

  return null;
};

PermissionFilter.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default PermissionFilter;
