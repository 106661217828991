import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  FormGroup,
  Flex,
  TextInput,
  Label,
  Panel,
  Heading1,
} from "@ubisend/pulse-components";
import { useMutation } from "@ubisend/pulse-hooks";

import { Logo } from "../../Components/Logo/index";
import { passwordReset } from "../../api/auth";

const PasswordRequest = ({ location }) => {
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  const email = new URLSearchParams(location.search).get("email");
  const token = new URLSearchParams(location.search).get("token");

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const mutation = useMutation(passwordReset, {
    onSuccess: () => {
      setRedirect(true);
    },
  });

  const valid = Boolean(password) && Boolean(token) && Boolean(email);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!valid) {
      return;
    }

    mutation.mutate({ email, password, token });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          // state: {
          //   notification: {
          //     type: "success",
          //     message: "You have been successfully logged In.",
          //   },
          // },
        }}
      />
    );
  }

  return (
    <Panel>
      <Flex col pad half mxAuto middle yScreen style={{ maxWidth: "25%" }}>
        <Flex middle mb>
          <Logo />
        </Flex>
        <Heading1 style={{ fontSize: "2rem" }} mb textCenter>
          Reset Password
        </Heading1>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Flex relative>
              <TextInput
                id="email"
                aria-label="email-input"
                placeholder="Email"
                value={email ?? ""}
                disabled
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Flex relative>
              <TextInput
                id="password"
                type={"password"}
                placeholder="New Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Flex>
          </FormGroup>
          <Flex ySpace center col>
            <Button
              aria-label="submit button"
              variant="primary"
              fat
              mt
              middle
              disabled={!valid}
              type="submit"
              loading={mutation.isLoading}
            >
              Reset password
            </Button>
            <Button variant="inline" as={Link} to="/login">
              Back to login
            </Button>
          </Flex>
        </form>
      </Flex>
    </Panel>
  );
};

PasswordRequest.propTypes = {};

export default PasswordRequest;
