import React from "react";
import PropTypes from "prop-types";

import { useQuery, useQueryClient, useMutation } from "@ubisend/pulse-hooks";
import { CreatableSelect, formatSelectOption } from "@ubisend/pulse-components";

import { createTag as createTagApi } from "../api/index";

const TagSelect = ({
  value,
  onChange,
  for: scope = "installs",
  disableCreate = false,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery([
    "tags",
    { scoped_to: scope },
  ]);
  const createTag = useMutation(createTagApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tags", { scoped_to: scope }]);
    },
  });

  const handleTagChange = async (initialOptions) => {
    const items = initialOptions || [];

    const tags = await Promise.all(
      items.map(async ({ __isNew__: isNew, ...item }) => {
        if (isNew && !disableCreate) {
          const { data } = await createTag.mutateAsync({
            name: item.value,
            scoped_to: scope,
          });
          return { value: data.data.id, label: data.data.name };
        }

        return item;
      })
    );

    onChange(tags);
  };

  return (
    <CreatableSelect
      closeMenuOnSelect
      isMulti
      isClearable
      isLoading={isLoading}
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      onChange={handleTagChange}
      placeholder="Tags"
      value={
        isSuccess &&
        value &&
        value.map((id) => {
          const value = data.data.find((tag) => tag.id === id);

          if (!value) {
            return null;
          }

          return formatSelectOption(value);
        })
      }
      {...props}
    />
  );
};

TagSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  for: PropTypes.oneOf(["installs"]),
};

export default TagSelect;
