import React from "react";
import { Switch, Route } from "react-router-dom";

import { Sidebar as SidebarComponent } from "./Sidebars/index";

const Sidebar = () => {
  return (
    <Switch>
      <Route render={(props) => <SidebarComponent {...props} />} />
    </Switch>
  );
};

export default Sidebar;
