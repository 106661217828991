import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import {
  SidebarItem as PulseSidebarItem,
  SidebarSubItem,
  Flex,
  Span
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const SidebarItem = ({
  label,
  icon,
  to,
  items = [],
  open,
  handleOpen,
  type
}) => {
  const { pathname } = useLocation();

  const hasItems = items?.length > 0;

  const LinkComponent = items.length > 0 ? 'button' : Link;

  const isActive = (pathname, link) => pathname === link;

  return (
    <PulseSidebarItem active={isActive(pathname, to)}>
      <LinkComponent to={to} onClick={handleOpen}>
        <Flex between fat>
          <Flex xSpaceSm center>
            <Icon
              type={icon}
              outline
              height="1.25rem"
              width="1.25rem"
              size="1.25rem"
            />
            <Span>{label}</Span>
          </Flex>
          {hasItems && (
            <Icon
              type={open ? 'cheveronUp' : 'cheveronDown'}
              height="1.25rem"
              width="1.25rem"
              size="1.25rem"
            />
          )}
        </Flex>
      </LinkComponent>
      {hasItems && open && (
        <Flex col ml>
          {items.map(({ to, label }, key) => (
            <SidebarSubItem
              key={key}
              active={isActive(pathname, to)}
              type={type}>
              <Link to={to}>{label}</Link>
            </SidebarSubItem>
          ))}
        </Flex>
      )}
    </PulseSidebarItem>
  );
};

SidebarItem.propTypes = {
  type: PropTypes.oneOf(['client', 'owner']),
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string
    })
  )
};

export default SidebarItem;
