import axios from "axios";

const createLicense = ({ project_id, account_id, start, end }) => {
  axios.post(`licenses/create`, {
    project_id,
    account_id,
    start: start,
    end: end,
  });
};

const editLicense = ({ project_id, license_id, start, end }) => {
  axios.put(`licenses/update`, {
    project_id,
    license_id,
    start: start,
    end: end,
  });
};

const deleteLicense = ({ project_id, license_id }) => {
  axios.delete(`licenses/destroy/${project_id}/${license_id}`);
};

export { createLicense, editLicense, deleteLicense };
