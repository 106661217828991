import React, { useEffect, useCallback } from "react";
import { useMutation } from "react-query";

import { Flex, Heading1, Paragraph } from "@ubisend/pulse-components";

import { useAuth } from "../../hooks/index";
import { logout } from "../../api/index";

const Logout = () => {
  const { authenticated, logoutUser } = useAuth();

  const mutation = useMutation(logout, {
    onSuccess: () => logoutUser(),
  });

  const handleLogout = useCallback(() => {
    !mutation.isLoading && mutation.mutate();
  }, [mutation]);

  useEffect(() => {
    if (authenticated) {
      handleLogout();
    }
  }, [authenticated, handleLogout]);

  return (
    <Flex col fat yScreen center middle pad>
      <Heading1 style={{ fontSize: "2rem" }} mb textCenter>
        Logging you out
      </Heading1>
      <Paragraph secondary textCenter>
        Please wait and you will be logged out
      </Paragraph>
    </Flex>
  );
};

export default Logout;
