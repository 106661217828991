import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  FormGroup,
  Flex,
  TextInput,
  Label,
  Panel,
  Heading1,
} from "@ubisend/pulse-components";
import { useMutation } from "@ubisend/pulse-hooks";

import { Logo } from "../../Components/Logo/index";
import { passwordRequest } from "../../api/auth";

const PasswordRequest = () => {
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const mutation = useMutation(passwordRequest, {
    onSuccess: () => {
      setRedirect(true);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    mutation.mutate({ email });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          // state: {
          //   notification: {
          //     type: "success",
          //     message: "You have been successfully logged In.",
          //   },
          // },
        }}
      />
    );
  }

  return (
    <Panel>
      <Flex col pad half mxAuto middle yScreen style={{ maxWidth: "25%" }}>
        <Flex middle mb>
          <Logo />
        </Flex>
        <Heading1 style={{ fontSize: "2rem" }} mb textCenter>
          Request New Password
        </Heading1>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Flex relative>
              <TextInput
                id="email"
                aria-label="email-input"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </Flex>
          </FormGroup>
          <Flex ySpace center col>
            <Button
              aria-label="submit button"
              variant="primary"
              fat
              mt
              middle
              type="submit"
              loading={mutation.isLoading}
              disabled={!email}
            >
              Request a new password
            </Button>
            <Button variant="inline" as={Link} to="/login">
              Take me back I remember
            </Button>
          </Flex>
        </form>
      </Flex>
    </Panel>
  );
};

PasswordRequest.propTypes = {};

export default PasswordRequest;
