import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  FormGroup,
  Flex,
  TextInput,
  Label,
  Panel,
  Heading1,
  Span,
} from "@ubisend/pulse-components";
import { useMutation } from "@ubisend/pulse-hooks";

import { Logo } from "../../Components/Logo/index";
import { acceptInvite } from "../../api/index";

const AcceptInvite = ({ location }) => {
  const [password, setPassword] = useState({
    initial: "",
    secondary: "",
    error: false,
  });
  const [name, setName] = useState({
    first: "",
    second: "",
  });
  const [redirect, setRedirect] = useState(false);

  const email = new URLSearchParams(location.search).get("email");
  const token = new URLSearchParams(location.search).get("token");

  const handleInitialPasswordChange = (e) =>
    setPassword({ ...password, initial: e.target.value });

  const handleSecondaryPasswordChange = (e) =>
    setPassword({ ...password, secondary: e.target.value });

  const handleFirstNameChange = (e) =>
    setName({ ...name, first: e.target.value });

  const handleSecondNameChange = (e) =>
    setName({ ...name, second: e.target.value });

  const mutation = useMutation(acceptInvite, {
    onSuccess: () => {
      setRedirect(true);
    },
  });

  const valid = Boolean(password.initial && password.secondary);
  const match = Boolean(password.initial === password.secondary);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password.error) {
      setPassword({ ...password, error: false });
    }

    if (!valid) {
      return;
    }

    if (!match) {
      return setPassword({ ...password, error: true });
    }

    mutation.mutate({
      email: email,
      first_name: name.first,
      last_name: name.second,
      password: password.initial,
      token,
    });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          // state: {
          //   notification: {
          //     type: "success",
          //     message: "You have been successfully logged In.",
          //   },
          // },
        }}
      />
    );
  }

  return (
    <Panel>
      <Flex col pad half mxAuto middle yScreen style={{ maxWidth: "25%" }}>
        <Flex middle mb>
          <Logo />
        </Flex>
        <Heading1 style={{ fontSize: "2rem" }} mb textCenter>
          You've been invited!
        </Heading1>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Flex relative>
              <TextInput
                id="email"
                aria-label="email-input"
                placeholder="Email"
                value={email}
                disabled
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="fname">First name</Label>
            <Flex relative>
              <TextInput
                id="fname"
                aria-label="fname"
                placeholder="Steve"
                value={name.first}
                onChange={handleFirstNameChange}
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lname">Second Name</Label>
            <Flex relative>
              <TextInput
                id="lname"
                aria-label="lname"
                placeholder="Jobbs"
                value={name.second}
                onChange={handleSecondNameChange}
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Flex relative>
              <TextInput
                id="password"
                type={"password"}
                placeholder="New Password"
                value={password.initial}
                onChange={handleInitialPasswordChange}
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password2">Confirm Password</Label>
            <Flex relative>
              <TextInput
                id="password2"
                type={"password"}
                placeholder="New Password"
                value={password.secondary}
                onChange={handleSecondaryPasswordChange}
              />
            </Flex>
          </FormGroup>
          {password.error && (
            <Flex middle mt mb>
              <Span colour="danger">Passwords do not match</Span>
            </Flex>
          )}
          <Flex ySpace center col>
            <Button
              variant="primary"
              fat
              mt
              middle
              disabled={!valid}
              type="submit"
              aria-label="submit-button"
              loading={mutation.isLoading}
            >
              Accept invite
            </Button>
            <Button variant="inline" as={Link} to="/login">
              Back to login
            </Button>
          </Flex>
        </form>
      </Flex>
    </Panel>
  );
};

AcceptInvite.propTypes = {};

export default AcceptInvite;
