import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  StretchPanel,
} from "@ubisend/pulse-components";

const UserDetails = ({ users }) => {
  return (
    <StretchPanel>
      <Table loadingColumns={3}>
        <TableHead>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Email</TableHeadCell>
            <TableHeadCell>Created At</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(({ first_name, last_name, email, created_at }) => (
            <TableRow key={`${first_name}${last_name}`}>
              <TableCell>{`${first_name} ${last_name}`}</TableCell>
              <TableCell>{email}</TableCell>
              <TableCell>{`${dayjs(created_at)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StretchPanel>
  );
};

UserDetails.propTypes = {
  users: PropTypes.any,
};

export default UserDetails;
