import React from "react";

import {
  InnerPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  StretchPanel,
  Flex,
  Tag,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";

const ExistingRoles = () => {
  const query = useQuery("roles");

  return (
    <InnerPanel header="Roles" mt>
      <StretchPanel>
        <Table loading={query.isLoading} loadingColumns={4}>
          {!query.isLoading && query.data?.data.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Permissions</TableHeadCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {query.data.data.map((role, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Tag>{role.name}</Tag>
                    </TableCell>
                    <TableCell>
                      <Flex col>
                        {role.permissions.map((permission) => (
                          <Flex key={permission.id}>{permission.name}</Flex>
                        ))}
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </StretchPanel>
    </InnerPanel>
  );
};

export default ExistingRoles;
