import React from "react";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";

import {
  PageWrapper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  Flex,
  StretchPanel,
  useOrderableTableReducer,
  usePaginationReducer,
  Button,
  NoResults,
  useModal,
  useNotification,
  Explainer,
  Pagination,
  OrderableTableHeadCell,
  Tooltip,
} from "@ubisend/pulse-components";
import Icon from "@ubisend/pulse-icons";
import { useAuth } from "../hooks/index";
import { useQuery } from "@ubisend/pulse-hooks";

import {
  requestExport as requestExportApi,
  refetchReports,
} from "../api/index";

dayjs.extend(relativeTime);

const Reporting = () => {
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { user } = useAuth();
  const pagination = usePaginationReducer({ id: "reports" });
  const order = useOrderableTableReducer({ id: "reports" });

  const query = useQuery([
    "reports",
    { ...pagination.params, ...order.params },
  ]);

  const getLastFetched = () => {
    const lastUpdatedAt = query.data.data.reduce((mostRecent, item) =>
      new Date(item.last_updated) > new Date(mostRecent.last_updated)
        ? item
        : mostRecent
    ).last_updated;

    return `Last Fetched: ${dayjs(lastUpdatedAt).from(dayjs())}`;
  };

  const { mutate: requestExport } = useMutation(requestExportApi);
  const { mutate: requestRefetch } = useMutation(refetchReports);

  const handleExport = () => {
    showModal({
      header: "Export reports to csv",
      body: `This will export a summary of all project reports that have been generated previously, including the most recent. The result will be emailed to you at ${user.email}.`,
      handleConfirm: async () => {
        try {
          const response = await requestExport({ id: user.id });

          if (response?.status === 200) {
            showSuccess(`Successfully requested reports export`);
          }
        } finally {
          hideModal();
        }
      },
    });
  };

  const handleRefetch = () => {
    showModal({
      header: "Refetch all reports",
      body: `This will mannually grab report data from each Pulse install configured with a client access token. This process could take some time.`,
      handleConfirm: async () => {
        try {
          const response = await requestRefetch();

          if (response?.status === 200) {
            showSuccess(`Successfully requested reports`);
          }
        } finally {
          hideModal();
        }
      },
    });
  };

  return (
    <PageWrapper
      header="Reporting"
      subheader={!query.isLoading && query.data && getLastFetched()}
      actions={
        <Flex xSpace>
          <Button onClick={handleRefetch} aria-label={"refetch-reports"}>
            <Flex xSpaceSm>
              <Icon outline type="plus" />
              <span>Refetch</span>
            </Flex>
          </Button>
          <Button
            variant="secondary"
            onClick={handleExport}
            aria-label={"request-export"}
          >
            <Flex xSpaceSm>
              <span>Export</span>
            </Flex>
          </Button>
        </Flex>
      }
    >
      <Flex mb>
        <Explainer type="note">
          Reports will automatically refresh each day at midnight. Only
          installations with a configured client id and secret will be fetched,
          this needs to be entered manually on the Projects dashboard.
        </Explainer>
      </Flex>
      <StretchPanel>
        <Table loading={query.isLoading} loadingColumns={4}>
          {!query.isLoading && query.data && (
            <>
              {!query.data.data.length === 0 && <NoResults />}
              {query.data.data.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableHeadCell>App name</TableHeadCell>
                    <OrderableTableHeadCell
                      row={{
                        label: "Number of clients",
                        sort: "latest_client_total",
                      }}
                      {...order.props}
                    />
                    <OrderableTableHeadCell
                      row={{
                        label: "Number of users",
                        sort: "latest_user_total",
                      }}
                      {...order.props}
                    />
                    <TableHeadCell>Actions</TableHeadCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {query.data.data.map(
                  (
                    {
                      project_id,
                      project_name,
                      total_users,
                      total_clients,
                      reports_are_fetchable,
                    },
                    key
                  ) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Flex xSpace>
                          {!reports_are_fetchable && (
                            <Tooltip
                              position={Tooltip.POSITIONS.RIGHT}
                              tooltip={
                                <Tooltip.Content>
                                  Unable to fetch new reports for this project.
                                  Ensure the projects Client ID and Secret are
                                  set correctly
                                </Tooltip.Content>
                              }
                            >
                              <Icon
                                stroke="red"
                                size="1rem"
                                width="1rem"
                                height="1rem"
                                type="exclamation"
                                outline
                                style={{ cursor: "auto" }}
                              />
                            </Tooltip>
                          )}

                          <Flex mr>{project_name}</Flex>
                        </Flex>
                      </TableCell>
                      <TableCell>{total_clients}</TableCell>
                      <TableCell>{total_users}</TableCell>
                      <TableCell>
                        <Flex>
                          {project_id && (
                            <Button
                              as={Link}
                              to={`/reports/${project_id}/report`}
                            >
                              <Flex xSpaceSm center>
                                <Icon outline type="eye" />
                                <span aria-label={`view-users-${project_id}`}>
                                  Info
                                </span>
                              </Flex>
                            </Button>
                          )}
                          {!project_id && (
                            <Button variant="secondary" disabled>
                              <Flex xSpaceSm center>
                                <Icon type="exclamationCircle" />
                                <span>Unavailable</span>
                              </Flex>
                            </Button>
                          )}
                        </Flex>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
    </PageWrapper>
  );
};
export default Reporting;
