import axios from "axios";

const getAllProjects = () => axios.get("projects");

const storeProject = (params) => axios.post("projects", params);

const deployProject = ({ id }) => axios.post(`projects/${id}/deploy`);

const deployProjectsByTag = ({ tags }) =>
  axios.post(`projects/deploy/tags`, {
    tags,
  });

const updateProjectSettings = ({ settings }) =>
  axios.post(`projects/${settings.id}/settings`, {
    client_id: settings.client_id,
    client_secret: settings.client_secret,
    tags: settings.tags ?? [],
  });

export {
  getAllProjects,
  storeProject,
  deployProject,
  updateProjectSettings,
  deployProjectsByTag,
};
