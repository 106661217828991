import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  Label,
  FormGroup,
  useModal,
  Explainer,
  useNotification,
} from "@ubisend/pulse-components";
import Icon from "@ubisend/pulse-icons";

import { TagSelect } from "../Components/index";
import { deployProjectsByTag as deployProjectsByTagApi } from "../api/index";

const Deploy = ({ handleCancel }) => {
  const [deployment, setDeployment] = useState({
    tags: [2],
  });

  const queryClient = useQueryClient();
  const { showModal, hideModal } = useModal();

  const handleTagsChange = (tags) =>
    setDeployment({ ...deployment, tags: tags.map(({ value }) => value) });

  const { showSuccess } = useNotification();
  const { mutate: deployProjectsByTag } = useMutation(deployProjectsByTagApi);

  const handleDeploy = ({ tags }) => {
    showModal({
      header: "Deploy Project",
      body: `Are you sure you want to deploy all projects with the selected tags?`,
      handleConfirm: async () => {
        try {
          const response = await deployProjectsByTag({ tags });

          if (response?.status === 200) {
            showSuccess(`Successfully deployed projects`);
            queryClient.invalidateQueries("projects");
          }
        } finally {
          hideModal();
          handleCancel();
        }
      },
    });
  };

  const handleSubmit = () => {
    handleDeploy(deployment);
  };

  return (
    <ModalPortal>
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Deploy Projects</ModalHeader>
            <ModalBody>
              <Flex mb>
                <Explainer type="note">
                  Selecting more than one tag will deploy any project with
                  either of those tags.
                </Explainer>
              </Flex>
              <FormGroup>
                <Label htmlFor="tags">Deploy all by tag</Label>
                <TagSelect
                  disableCreate
                  id="tags"
                  value={deployment.tags}
                  onChange={handleTagsChange}
                  for="installs"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={deployment.tags.length === 0}
                  variant="secondary"
                  onClick={() => handleSubmit()}
                >
                  <Flex xSpaceSm center>
                    <Icon outline type="airplane" />
                    <span>Deploy</span>
                  </Flex>
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

Deploy.propTypes = {
  handleCancel: PropTypes.func,
};

export default Deploy;
