import React, { useState } from "react";
import { useQueryClient } from "react-query";

import { useQuery } from "@ubisend/pulse-hooks";

import { AuthContext } from "../Context/index";

const AuthProvider = ({ children }) => {
  const initCache = (key, parse) => {
    const payload = localStorage.getItem(key) ?? null;

    return parse ? JSON.parse(payload) : payload;
  };

  const [token, setToken] = useState(initCache("token"));
  const [user, setUser] = useState(initCache("user", true));

  const queryClient = useQueryClient();

  const loginUser = ({ token, user }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    setToken({ token });
    setUser(user);
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    setToken(null);
    setUser(null);
  };

  const revokeToken = () => logoutUser();

  const refetchMutation = useQuery("auth/check", {
    enabled: false,
    onSuccess: () => console.log("refetched"),
    onError: () => {
      localStorage.removeItem("token");
      logoutUser();
      queryClient.invalidateQueries();
    },
  });

  const context = {
    authenticated: Boolean(token && user),
    token,
    user,
    loginUser,
    logoutUser,
    revokeToken,
    refetchMutation,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
