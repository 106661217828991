import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  FormGroup,
  Flex,
  TextInput,
  Label,
  Panel,
} from "@ubisend/pulse-components";
import { useMutation } from "@ubisend/pulse-hooks";

import { Logo } from "../../Components/Logo/index";
import { login } from "../../api/auth";
import { useAuth } from "../../hooks/index";

const Login = () => {
  const [creds, setCreds] = useState({ email: "", password: "" });

  const { authenticated, loginUser } = useAuth();

  const handleEmailChange = (e) =>
    setCreds({ ...creds, email: e.target.value });

  const handlePasswordChange = (e) =>
    setCreds({ ...creds, password: e.target.value });

  const mutation = useMutation(login, {
    onSuccess: ({ data }) => {
      const { token, ...rest } = data.data;
      loginUser({ token, user: rest });
    },
  });

  const valid = Boolean(creds.email && creds.password);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!valid) {
      return;
    }

    mutation.mutate({ email: creds.email, password: creds.password });
  };

  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
          state: {
            notification: {
              type: "success",
              message: "You have been successfully logged In.",
            },
          },
        }}
      />
    );
  }

  return (
    <Panel>
      <Flex col pad half mxAuto middle yScreen style={{ maxWidth: "25%" }}>
        <Flex middle mt>
          <Logo />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Flex relative>
              <TextInput
                id="email"
                aria-label="email-input"
                placeholder="Email"
                value={creds.email}
                onChange={handleEmailChange}
              />
            </Flex>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Flex relative>
              <TextInput
                id="password"
                aria-label="password-input"
                type={"password"}
                placeholder="Password"
                value={creds.password}
                onChange={handlePasswordChange}
              />
            </Flex>
          </FormGroup>
          <Flex ySpace center col>
            <Button
              variant="primary"
              fat
              mt
              middle
              disabled={!valid}
              type="submit"
              loading={mutation.isLoading}
            >
              {mutation.isLoading ? "Logging in" : "Log in"}
            </Button>
            <Button variant="inline" as={Link} to="/password/request">
              Forgot your password?
            </Button>
          </Flex>
        </form>
      </Flex>
    </Panel>
  );
};

Login.propTypes = {};

export default Login;
