import React, { useEffect } from "react";
import axios from "axios";

import { useNotification } from "@ubisend/pulse-components";

import Routes from "./Routes/Routes";
import { Layout } from "./Components/index";
import { useAuth } from "./hooks/index";

const App = () => {
  const { showError } = useNotification();
  const { refetchMutation } = useAuth();

  useEffect(() => {
    axios.interceptors.response.use(
      (config) => config,
      (error) => {
        if (error?.response.status === 401) {
          !refetchMutation.isLoading && refetchMutation.refetch();
        }

        return Promise.reject(error);
      }
    );
  }, [showError, refetchMutation]);

  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default App;
