import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import PropTypes from "prop-types";

import {
  AppContainer,
  Notifications,
  Modals,
  Flex,
} from "@ubisend/pulse-components";
import { useAuth } from "../hooks/index";

import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const { authenticated } = useAuth();

  return (
    <>
      <Modals />
      <Notifications />
      {authenticated ? (
        <Flex>
          <Sidebar />
          <AppContainer>{children}</AppContainer>
        </Flex>
      ) : (
        <>{children}</>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
