import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";

import {
  PageWrapper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  Flex,
  PanelSlider,
  StretchPanel,
  Button,
  NoResults,
  usePaginationReducer,
  useModal,
  Pagination,
  Tag,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";

import { useAuth } from "../../hooks/index";
import { removeUser } from "../../api/index";
import InviteDetails from "./InviteDetails";
import ExistingInvites from "./ExistingInvites";
import RoleDetails from "./RoleDetails";
import ExistingRoles from "./ExistingRoles";
import { PermissionFilter } from "../../Components/index";

const UsersPage = () => {
  const [invite, setInvite] = useState(false);
  const [roles, setRoles] = useState(false);
  const query = useQuery("users");
  const queryClient = useQueryClient();

  const { user: currentUser } = useAuth();
  const pagination = usePaginationReducer({ id: "users" });
  const { showModal, hideModal } = useModal();

  const mutation = useMutation(removeUser, {
    onSuccess: () => queryClient.refetchQueries("users"),
  });

  const handleRemove = (user) => {
    showModal({
      header: "Remove User",
      body: `Are you sure you want to remove ${user.first_name} ${user.last_name} (${user.email})?`,
      handleConfirm: async () => {
        try {
          mutation.mutate({ id: user.id });
        } finally {
          hideModal();
        }
      },
    });
  };

  return (
    <PageWrapper
      header={"Manage Users"}
      actions={
        <Flex xSpace>
          <PermissionFilter permission="edit users">
            <Button onClick={() => setInvite(true)}>+ Invites</Button>
            <Button onClick={() => setRoles(true)}>Manage Roles</Button>
          </PermissionFilter>
        </Flex>
      }
    >
      {invite && (
        <PanelSlider
          width={"640px"}
          header={`Invite Users`}
          handleHide={() => setInvite(null)}
        >
          <InviteDetails handleClose={() => setInvite(null)} />
          <ExistingInvites />
        </PanelSlider>
      )}
      {roles && (
        <PanelSlider
          width={"640px"}
          header={`Manage Roles`}
          handleHide={() => setRoles(null)}
        >
          <RoleDetails handleClose={() => setRoles(null)} />
          <ExistingRoles />
        </PanelSlider>
      )}
      <StretchPanel>
        <Table loading={query.isLoading} loadingColumns={4}>
          {!query.isLoading && query.data?.data && (
            <>
              {!query.data.data.length === 0 && <NoResults />}
              {query.data.data.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Email</TableHeadCell>
                    <TableHeadCell>Roles</TableHeadCell>
                    <PermissionFilter permission="edit users">
                      <TableHeadCell>Actions</TableHeadCell>
                    </PermissionFilter>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {query.data.data.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                    <TableCell>{user.email ? user.email : "N/A"}</TableCell>
                    <TableCell>
                      {user.roles.length > 0 && <Tag>{user.roles}</Tag>}
                    </TableCell>
                    <PermissionFilter permission="edit users">
                      <TableCell>
                        <Flex>
                          <Button
                            aria-label={`delete-${user.id}`}
                            variant="secondary"
                            colour="danger"
                            icon="trash"
                            disabled={user.id === currentUser.id}
                            onClick={() => handleRemove(user)}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </TableCell>
                    </PermissionFilter>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default UsersPage;
