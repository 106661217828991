import React, { useState } from "react";
import { useMutation } from "react-query";

import {
  Flex,
  Button,
  Checkbox,
  Label,
  FormGroup,
  TextInput,
  InnerPanel,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";

import { useAuth } from "../../hooks/index";
import { inviteUser } from "../../api/index";

const defaultInvite = {
  email: "",
  role: "",
};

const InviteDetails = ({ handleClose }) => {
  const [user, setUser] = useState(defaultInvite);

  const query = useQuery("roles");
  const { user: authUser } = useAuth();

  const mutation = useMutation(inviteUser, {
    onSuccess: () => handleClose(),
  });

  const handleSelectRole = (role) => {
    if (user.role === role.id) {
      return setUser({ ...user, role: "" });
    }

    setUser({ ...user, role: role.id });
  };

  const handleEmailChange = (e) => setUser({ ...user, email: e.target.value });

  const handleSubmit = () => {
    mutation.mutate({
      id: authUser.id,
      email: user.email,
      role_id: user.role,
    });
  };

  const valid = Boolean(user.email && user.role);

  return (
    <InnerPanel>
      <FormGroup>
        <Label htmlFor="email">Email</Label>
        <TextInput
          id="email"
          placeholder="name@email.com"
          onChange={handleEmailChange}
          value={user.email}
        />
      </FormGroup>
      <FormGroup>
        <Label>Role</Label>
        {query.isSuccess &&
          query.data.data.map((role) => (
            <Flex mt key={role.id}>
              <Checkbox
                aria-label={`role-select-${role.id}`}
                checked={user.role === role.id}
                label={role.name}
                onChange={() => handleSelectRole(role)}
              />
            </Flex>
          ))}
      </FormGroup>
      <Flex right>
        <Button
          aria-label="submit button"
          variant="primary"
          onClick={() => handleSubmit()}
          disabled={!valid}
        >
          Send invite
        </Button>
      </Flex>
    </InnerPanel>
  );
};

export default InviteDetails;
