import React from "react";

import Image from "../../images/beat.png";
import "./styles.css";

const Logo = () => (
  <div className="beat">
    <div>
      <img src={Image} alt="pulse beat logo" />
    </div>
  </div>
);

export default Logo;
