import React from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../hooks/index";

const Route = ({ public: isPublic, ...props }) => {
  const { authenticated } = useAuth();

  if (isPublic && !authenticated) {
    return <RouterRoute {...props} />;
  }

  if (isPublic && authenticated) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          //   state: {
          //     notification: {
          //       type: "error",
          //       message:
          //         "You are not currently logged in so can't access that page.",
          //     },
          //   },
        }}
      />
    );
  }

  return <RouterRoute {...props} />;
};

Route.propTypes = {
  public: PropTypes.bool,
};

export default Route;
