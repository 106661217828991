import React from "react";

const AuthContext = React.createContext({
  user: null,
  token: null,
  authenticated: false,
  loginUser: () => {},
  logoutUser: () => {},
});

export default AuthContext;
