import React, { useState } from "react";
import { useMutation } from "react-query";

import {
  Flex,
  Button,
  Checkbox,
  Label,
  FormGroup,
  TextInput,
  InnerPanel,
} from "@ubisend/pulse-components";
import { useQuery } from "@ubisend/pulse-hooks";

import { createRole } from "../../api/index";

const defaultRole = {
  name: "",
  permissions: [],
};

const RoleDetails = ({ handleClose }) => {
  const [role, setRole] = useState(defaultRole);

  const query = useQuery("permissions");

  const mutation = useMutation(createRole, {
    onSuccess: () => handleClose(),
  });

  const handleSelectPermission = (permission) => {
    if (role.permissions.includes(permission.id)) {
      return setRole({
        ...role,
        permissions: role.permissions.filter((id) => id !== permission.id),
      });
    }

    setRole({ ...role, permissions: role.permissions.concat(permission.id) });
  };

  const handleNameChange = (e) => setRole({ ...role, name: e.target.value });

  const handleSubmit = () => {
    mutation.mutate({
      name: role.name,
      permissions: role.permissions,
    });
  };

  const valid = Boolean(role.name && role.permissions.length > 0);

  return (
    <InnerPanel>
      <FormGroup>
        <Label htmlFor="role-name">Role Name</Label>
        <TextInput
          id="role-name"
          placeholder="King of the World"
          onChange={handleNameChange}
          value={role.name}
        />
      </FormGroup>
      <FormGroup>
        <Label>Permissions</Label>
        {query.isSuccess &&
          query.data.data.map((permission) => (
            <Flex mt key={permission.id}>
              <Checkbox
                checked={role.permissions.includes(permission.id)}
                label={permission.name}
                onChange={() => handleSelectPermission(permission)}
              />
            </Flex>
          ))}
      </FormGroup>
      <Flex right>
        <Button
          variant="primary"
          onClick={() => handleSubmit()}
          disabled={!valid}
        >
          Create
        </Button>
      </Flex>
    </InnerPanel>
  );
};

export default RoleDetails;
